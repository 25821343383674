import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay'; // Import autoplay styles

import './swipimage.css';

// import required modules
import { Pagination, Autoplay } from 'swiper/modules'; // Import Autoplay module

function SwipImage() {
  // Array of objects with image URLs (local) and corresponding links
  const slides = [

    { imageUrl: '/black Fiday.png', link: 'https://getgutschein.de/shop' },
    { imageUrl: '/trip.com.jpg', link: 'https://getgutschein.de/OpenStore/118/Trip.com' },
    { imageUrl: '/wolt 1280.jpg', link: 'https://getgutschein.de/OpenStore/119/Wolt' },
    { imageUrl: '/fasion 1280.jpg', link: 'https://getgutschein.de/DisplayCategories/7/%20Mode%20&%20Accessoires' },
    
   
  ];

  return (
    <Swiper
      pagination={{
        dynamicBullets: true,
      }}
      autoplay={{
        delay: 3000, // Time delay between slides in milliseconds (3 seconds)
        disableOnInteraction: false, // Continue autoplay even after user interaction
      }}
      modules={[Pagination, Autoplay]} // Include Autoplay module
      className="mySwiper"
    >
      {/* Loop through the slides array */}
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          {/* Link around the image */}
          <a href={slide.link} target="_blank" rel="noopener noreferrer">
            <img 
              src={slide.imageUrl} 
              alt={`Slide ${index + 1}`} 
              className="swiper-slide-image" // Add class to apply uniform styles
            />
          </a>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default SwipImage;
